import React, { FC, useEffect } from 'react';
import { Box, Container, Theme, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Icon404 } from 'assets/svgs/errors';
import { Energy5Link } from 'components/energy5/atoms';
import { useRouter } from 'next/router';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(10, 0),
    width: '100%',
    textAlign: 'center',
  },

  title: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.dark,
  },

  text: {
    marginBottom: theme.spacing(6),
    color: theme.palette.text.primary,
  },

  button: {
    margin: '0 auto',
  },
}));

const data = {
  retail: {
    title: 'Oops! Something\'s Missing',
    subTitle: 'However, your savings are just one click away!',
    text: 'You\'re here because the page you are looking for is connected to our electricity and natural gas prices. In just a few simple steps, you can compare your area\'s rates. You\'ll soon be redirected to our main page, or click below to continue now!',
    link: '/compare-energy-rates',
    linkText: 'Check rates'
  },
  home: {
    title: 'Oops, the page was not found!',
    subTitle: '',
    text: 'We can’t find the page you’re looking for. Please check the URL and try again or go back to where you were.',
    link: '/',
    linkText: 'Go Home',
  }
};

const PageNotFound: FC = () => {
  const { asPath, push } = useRouter();
  const isRetail = asPath.includes('retail');
  const content = isRetail ? data.retail : data.home;
  const classes = useStyles();

  useEffect(() => {
    if (isRetail) {
      setTimeout(() => {
        push(data.retail.link)
      }, 5000)
    }
  }, []);

  return (
    <Container maxWidth={false} fixed>
      <Grid container justifyContent="center" alignItems="center" className={classes.root}>
        <Grid item md={10} xs={12}>
          <Box>
            <Icon404 />
          </Box>
          <Typography variant={'h2'} className={classes.title}>
            {content.title}
          </Typography>
          <strong>{content.subTitle}</strong>
          <Typography variant={'h6'} className={classes.text}>
            {content.text}
          </Typography>
          <Energy5Link href={content.link} text={content.linkText} className={classes.button} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default PageNotFound;
