import React, { FC } from 'react';
import PageNotFound from 'containers/common/sections/errors/PageNotFound';
import { Page } from 'containers/common/sections/layout';

const New404: FC = () => {
  return (
    <Page hideFooterOnly title={'Page not found'} description={'Page not found'}>
      <PageNotFound />
    </Page>
  );
};
export default New404;
