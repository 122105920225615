import React, { FC, forwardRef } from 'react';
import { SvgIconProps } from '@material-ui/core';
import Icon404Svg from 'assets/svgs/errors/Icon404Svg.svg';
import Icon500Svg from 'assets/svgs/errors/Icon500Svg.svg';

export const Icon404: FC<SvgIconProps> = forwardRef((props, ref) => (
  <Icon404Svg ref={ref} {...props} />
));

export const Icon500: FC<SvgIconProps> = forwardRef((props, ref) => (
  <Icon500Svg ref={ref} {...props} />
));
